<template>
  <CSidebar class="bg-main" fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand class="d-md-down-none mb-2" to="/">
      <img class="mb-3" src="@/assets/logo/logo-putih.png" width="20%" alt="" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="computedSidebar" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import * as data from "./_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      role: {},
    };
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    getRoleFromLocal() {
      return JSON.parse(localStorage.getItem("user"));
    },
    computedSidebar() {
      let menus = [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/dashboard',
          icon: 'cil-speedometer',
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Menu']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan User',
          to: '/user',
          icon: 'cil-user',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Member',
          to: '/survey',
          icon: 'cil-file',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Job',
          to: '/job',
          icon: 'cil-laptop',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Struktur',
          to: '/member',
          icon: 'cil-user',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Produk',
          to: '/product',
          icon: 'cil-file',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Banner',
          to: '/banner',
          icon: 'cil-layers',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Gallery',
          to: '/gallery',
          icon: 'cil-list',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan Pengumuman',
          to: '/pengumuman',
          icon: 'cil-speech',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Pengelolaan News',
          to: '/news',
          icon: 'cil-layers',
        },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Pengelolaan Posting',
        //   to: '/posting',
        //   icon: 'cil-layers',
        // },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Pengelolaan Komentar',
        //   to: '/komentar',
        //   icon: 'cil-layers',
        // },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Pengelolaan Likes',
        //   to: '/likes',
        //   icon: 'cil-layers',
        // },
      ]
      return [
        {
          _name: "CSidebarNav",
          _children: menus,
        },
      ];
    },
  },
};
</script>


<style>
  /* Adjust the color palette based on your preferences */
  .bg-main {
    background: #10623f !important;
    color: #fff;
  }

  .c-sidebar {
    transition: width 0.3s ease;
  }

  .c-sidebar .c-sidebar-brand {
    background: #10623f !important;
  }

  .c-sidebar-nav-item {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .c-sidebar-nav-item:hover {
    background-color: #155734;
    transition: background-color 0.3s ease;
  }
</style>